import { graphql } from "gatsby"
import React from "react"
import PropTypes from "prop-types"
import Img from "../../components/core/img"
import SVGInline from "react-svg-inline"
import Plx from "react-plx"
import windowSize from "react-window-size"

import Layout from "../../layouts"
import Section from "../../components/section"
import FeaturesSection from "../../components/features"
import Container from "../../components/container"
import HeroContainer from "../../components/containers/hero"
import SEO from "../../components/seo"
import Metaball from "../../components/metaball"
import Footer from "../../components/footer"
import Header from "../../components/header"
import Title from "../../components/hero/title"
import LinkToParentPage from "../../components/link-to-parent-page"
import PullBelowHeader from "../../components/containers/pull-below-header"

import { rhythm } from "../../utils/typography"
import {
  howItWorksSVGStyles,
  titleStyles,
  subtitleStyles,
  gridBackground,
} from "../../utils/styles"

const Subhead = ({ subhead, isIndex }) => {
  const defaultStyles = theme => ({
    color: theme.colors.blue[90],
    fontWeight: 100,
    marginTop: 0,
    marginLeft: `auto`,
    marginRight: `auto`,
    textAlign: `center`,
    [theme.mediaQueries.tablet]: {
      textAlign: `left`,
      marginLeft: 0,
      marginRight: 0,
    },
  })

  const indexStyles = theme => [
    titleStyles,
    {
      [theme.mediaQueries.mobile]: {
        maxWidth: rhythm(10),
      },
      [theme.mediaQueries.phablet]: {
        maxWidth: rhythm(14),
      },
      [theme.mediaQueries.tablet]: {
        maxWidth: rhythm(16),
        paddingRight: theme.space[8],
        marginBottom: theme.space[8],
      },
    },
  ]

  const subsectionStyles = theme => [
    subtitleStyles,
    {
      marginBottom: 0,
      [theme.mediaQueries.tablet]: {
        marginBottom: theme.space[8],
      },
    },
  ]

  return (
    <h2
      css={theme => [
        defaultStyles(theme),
        isIndex ? indexStyles(theme) : subsectionStyles(theme),
      ]}
    >
      {subhead}
    </h2>
  )
}

Subhead.propTypes = {
  subhead: PropTypes.string.isRequired,
  isIndex: PropTypes.bool,
}

const Column = ({ children }) => (
  <div
    css={theme => ({
      [theme.mediaQueries.tablet]: {
        width: `50%`,
        flexGrow: 0,
      },
    })}
  >
    {children}
  </div>
)

Column.propTypes = {
  customStyles: PropTypes.object,
}

const borderAbs = theme => [
  gridBackground(theme),
  {
    content: `" "`,
    display: `block`,
    position: `absolute`,
    left: `-100%`,
    right: 0,
  },
]

const WithBorder = ({ children, noBottom }) => (
  <div
    className="WithBorder"
    css={theme => ({
      position: `relative`,
      zIndex: 100,
      "&:after": [
        borderAbs(theme),
        {
          ...borderAbs,
          bottom: 0,
          height: noBottom ? 0 : 1,
        },
      ],
      "&:before": [
        borderAbs(theme),
        {
          top: 0,
        },
      ],
    })}
  >
    {children}
  </div>
)

WithBorder.propTypes = {
  noBottom: PropTypes.bool,
  isIndex: PropTypes.bool,
}

const verticalLineStyles = theme => [
  gridBackground(theme),
  {
    content: `" "`,
    width: 1,
    top: 0,
    bottom: 0,
    position: `absolute`,
  },
]

const VerticalGrid = () => (
  <div
    css={{
      position: `fixed`,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 1,
      pointerEvents: `none`,
      userSelect: `none`,
    }}
  >
    <Container
      css={theme => ({
        height: `100%`,
        "&:after": [
          verticalLineStyles(theme),
          {
            left: 0,
          },
        ],
        "&:before": [
          verticalLineStyles(theme),
          {
            right: 0,
            left: `auto`,
          },
        ],
      })}
      innerCustomStyles={{ height: `100%` }}
    >
      <div
        css={theme => ({
          height: `100%`,
          position: `relative`,
          "&:after": [
            verticalLineStyles(theme),
            {
              left: 0,
            },
          ],
          "&:before": [
            verticalLineStyles(theme),
            {
              right: 0,
              left: `auto`,
            },
          ],
        })}
      >
        <span
          css={theme => [
            verticalLineStyles(theme),
            {
              opacity: 0.75,
              right: `50%`,
              height: `100%`,
              left: `auto`,
              [theme.mediaQueries.tablet]: {
                "&:after": [
                  verticalLineStyles(theme),
                  {
                    left: `-${theme.space[8]}`,
                  },
                ],
                "&:before": [
                  verticalLineStyles(theme),
                  {
                    right: `-${theme.space[8]}`,
                    left: `auto`,
                  },
                ],
              },
            },
          ]}
        />
      </div>
    </Container>
  </div>
)

const ImageCredit = () => (
  <div
    css={{
      position: `absolute`,
      right: 0,
      bottom: 0,
    }}
  >
    <div
      css={{
        transform: `rotate(90deg)`,
        transformOrigin: `right top`,
      }}
    >
      <p
        css={theme => ({
          color: theme.colors.blue[90],
          fontSize: 8,
          opacity: 0.2,
        })}
      >
        Image © NASA
      </p>
    </div>
  </div>
)

class HowItWorksPage extends React.Component {
  render() {
    const { contentfulPage } = this.props.data
    const { title, subtitle, sections, name } = contentfulPage
    const clientHeight = this.props.windowHeight || 1
    const blueprint = this.props.data.blueprint.childImageSharp.fluid
    const Keyvisual =
      contentfulPage.keyvisual && contentfulPage.keyvisual.name
        ? require(`!raw-loader!svgo-loader?{"plugins":[{"removeViewBox":false},{"removeDimensions":true}]}!../../assets/${contentfulPage.keyvisual.name}.svg`)
        : false
    const isIndex = contentfulPage.name === `How It Works`

    return (
      <Layout pathname={this.props.location.pathname}>
        <SEO contentfulPage={contentfulPage} url={this.props.location.href} />
        <Header />
        <PullBelowHeader>
          <HeroContainer isInverted customStyles={{ zIndex: 1 }}>
            <VerticalGrid />
            <Plx
              animateWhenNotInViewport
              parallaxData={[
                {
                  duration: clientHeight * 1.5,
                  offset: -clientHeight,
                  properties: [
                    {
                      endValue: -200,
                      property: `translateY`,
                      startValue: 0,
                    },
                  ],
                  start: `self`,
                },
              ]}
              css={{ top: `-4vh`, position: `relative` }}
            >
              <Metaball css={theme => ({ color: theme.colors.blue[5] })} />
            </Plx>
            <Container
              css={theme => ({
                position: `relative`,
                zIndex: 1,
                [theme.mediaQueries.mobile]: { minHeight: `30vh` },
                [theme.mediaQueries.tablet]: { minHeight: 0 },
                "&:after": [
                  borderAbs(theme),
                  {
                    right: `-100%`,
                    bottom: 0,
                    height: 1,
                  },
                ],
              })}
            >
              <div
                css={theme => ({
                  [theme.mediaQueries.tablet]: {
                    alignItems: `flex-end`,
                    display: `flex`,
                    position: `relative`,
                    width: `100%`,
                  },
                })}
              >
                <Column>
                  {contentfulPage.parentPage && (
                    <div
                      css={theme => ({
                        textAlign: `center`,
                        [theme.mediaQueries.tablet]: { textAlign: `left` },
                      })}
                    >
                      <LinkToParentPage contentfulPage={contentfulPage} />
                    </div>
                  )}
                  <WithBorder isIndex={isIndex} noBottom>
                    <Title
                      css={theme => ({
                        marginBottom: 0,
                        color: theme.colors.blue[90],
                        textAlign: `center`,
                        [theme.mediaQueries.tablet]: {
                          textAlign: `left`,
                        },
                      })}
                    >
                      {title}
                    </Title>
                  </WithBorder>
                  <WithBorder>
                    <Subhead
                      subhead={
                        // Replace the space before the last word in the string
                        // with a unicode &nbsp;
                        //
                        // The current "How It Works" subtitle is
                        // "The Gatsby Way of Building". Along the
                        // custom <Subhead /> styles (maxWidth) this ensures
                        // that "The Gatsby Way" always gets its own line
                        // (at least up to the "Hd" breakpoint)
                        isIndex
                          ? subtitle.subtitle.replace(
                              /\s([^\s<]+)\s*$/,
                              `\u00A0$1`
                            )
                          : subtitle.subtitle
                      }
                      isIndex={isIndex}
                    />
                  </WithBorder>
                </Column>
                <Column>
                  {isIndex ? (
                    <div
                      css={theme => ({
                        position: `relative`,
                        [theme.mediaQueries.mobile]: {
                          padding: `0 0 ${theme.space[8]}`,
                        },
                        [theme.mediaQueries.tablet]: {
                          padding: `0 0 ${theme.space[8]} ${theme.space[8]}`,
                          margin: `0 auto`,
                        },
                      })}
                    >
                      <Img fluid={blueprint} />
                      <ImageCredit />
                    </div>
                  ) : (
                    <div
                      css={theme => ({
                        opacity: 0.5,
                        position: `relative`,
                        "& span": {
                          display: `block`,
                          margin: `0 auto`,
                          [theme.mediaQueries.tablet]: {
                            width: `100%`,
                            paddingBottom: `100%`,
                          },
                          [theme.mediaQueries.desktop]: {
                            marginTop: -80,
                            width: `80%`,
                            paddingBottom: `80%`,
                          },
                        },
                        "& svg": [
                          howItWorksSVGStyles(theme),
                          {
                            position: `absolute`,
                            top: 0,
                            left: 0,
                            height: `100%`,
                            width: `100%`,
                          },
                        ],
                      })}
                    >
                      {Keyvisual && <SVGInline svg={Keyvisual} />}
                    </div>
                  )}
                </Column>
              </div>
            </Container>
          </HeroContainer>
        </PullBelowHeader>
        {(sections || []).map((section, i) =>
          section.featuresList ? (
            <div key={i} css={{ counterReset: `how-it-works-counter` }}>
              <FeaturesSection section={section} key={i} index={i} />
            </div>
          ) : (
            <Container key={i}>
              <Section section={section} page={name} index={i} />
            </Container>
          )
        )}
        <Footer />
      </Layout>
    )
  }
}

HowItWorksPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}

export default windowSize(HowItWorksPage)

export const pageQuery = graphql`
  query ContentfulHowItWorksPage($id: String!) {
    blueprint: file(relativePath: { regex: "/how-it-works-keyvisual.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1280, quality: 80, traceSVG: { color: "#e9f2fb" }) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    contentfulPage(id: { eq: $id }) {
      name
      title
      subtitle {
        subtitle
      }
      parentPage {
        name
        slug
      }
      keyvisual {
        name
      }
      ...SocialMediaImage
      sections {
        title
        linkText
        associatedPage {
          name
          slug
          parentPage {
            name
            slug
          }
        }
        layout
        text {
          childMarkdownRemark {
            html
          }
        }
        keyvisual {
          name
        }
        ...FeaturesListFragment
      }
    }
  }
`
